const FreeCard = () => {
  return (
    <div className="text-gray-700">
      {/* <button className="px-5 py-3 rounded-lg text-white bg-blue-900 hover:bg-blue-700 dark:bg-yellow-600">Log out</button>  */}
      © Appburn Mobility Lanka 2024
    </div>
  );
};

export default FreeCard;
