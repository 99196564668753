
// Payment tour page

const Payment = () =>{

    return(
        <div>
             <h1>Payment page</h1>

        </div>
    );
}

export default Payment;