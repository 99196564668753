

const TravelPackageForm = () => {
   

    return (
       <div>
               <h1 className="text-2xl font-semibold mb-4">Create Multi-day Travel Package</h1>
       </div>
         
            
      
    );
};

export default TravelPackageForm;
