

const UserBooking = () => {
   
    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">UserBooking Details</h1>
          
        </div>
    );
}

export default UserBooking;
